import { FC, lazy, LazyExoticComponent, SVGProps } from 'react';

import { brandName } from './constants';
import { BrandName } from '../types/brand';

type BrandedLogo = LazyExoticComponent<FC<SVGProps<SVGSVGElement>>>;

type LoginOptions = {
  hasPassword: boolean;
  hasCheckbox: boolean;
};

interface BrandedData {
  clientApp: string;
  applicationName: string;
  applicationTitle: string;
  appScheme: string;
  APP_STORE: string;
  GOOGLE_PLAY: string;
  APP_GALLERY: string;
  logo: BrandedLogo;
  shortLogo?: BrandedLogo;
  landing?: string;
  loginOptions: LoginOptions;
  defaultLang: 'ru' | 'en';
  poweredBy: 'waveservice' | 'tagpoint';
  poweredByIcon: 'ws-black' | 'tp-black';
}

const officeServiceLogo = lazy(() =>
  import(
    /* webpackChunkName: "officeServiceLogo" */ 'lib/ui/icon/glyphs/textLogos/officeservice.svg'
  ).then(({ ReactComponent }) => ({
    default: ReactComponent,
  })),
);

const tagpointLogo = lazy(() =>
  import(/* webpackChunkName: "tagpointLogo" */ 'lib/ui/icon/glyphs/textLogos/tagpoint.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const teoremaLogo = lazy(() =>
  import(/* webpackChunkName: "teoremaLogo" */ 'lib/ui/icon/glyphs/textLogos/teorema.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const waveServiceLogo = lazy(() =>
  import(/* webpackChunkName: "waveServiceLogo" */ 'lib/ui/icon/glyphs/textLogos/ws.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const glandiaLogo = lazy(() =>
  import(/* webpackChunkName: "glandiaLogo" */ 'lib/ui/icon/glyphs/textLogos/glandia.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const zabotaLogo = lazy(() =>
  import(/* webpackChunkName: "zabotaLogo" */ 'lib/ui/icon/glyphs/textLogos/zabota.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const zabotaShortLogo = lazy(() =>
  import(
    /* webpackChunkName: "zabotaShortLogo" */ 'lib/ui/icon/glyphs/shortTextLogos/nlmk.svg'
  ).then(({ ReactComponent }) => ({
    default: ReactComponent,
  })),
);

const gsoLogo = lazy(() =>
  import(/* webpackChunkName: "gsoLogo" */ 'lib/ui/icon/glyphs/textLogos/gsp.svg').then(
    ({ ReactComponent }) => ({
      default: ReactComponent,
    }),
  ),
);

const waveServiceShortLogo = lazy(() =>
  import(/* webpackChunkName: "waveServiceShortLogo" */ 'lib/ui/icon/glyphs/ws.svg').then(
    ({ ReactComponent }) => ({ default: ReactComponent }),
  ),
);

const tagpointShortLogo = lazy(() =>
  import(
    /* webpackChunkName: "tagpointShortLogo" */ 'lib/ui/icon/glyphs/shortTextLogos/TagPoint.svg'
  ).then(({ ReactComponent }) => ({
    default: ReactComponent,
  })),
);

const codeserviceLogo: BrandedLogo = lazy(() =>
  import(
    /* webpackChunkName: "codeserviceLogo" */ 'lib/ui/icon/glyphs/textLogos/codeservice.svg'
  ).then(({ ReactComponent }) => ({ default: ReactComponent })),
);

const nornikelLogo: BrandedLogo = lazy(() =>
  import(/* webpackChunkName: "zabota-24Logo" */ 'lib/ui/icon/glyphs/textLogos/nrnikel.svg').then(
    ({ ReactComponent }) => ({ default: ReactComponent }),
  ),
);

const nornikelShortLogo = lazy(() =>
  import(
    /* webpackChunkName: "nornikelShortLogo" */ 'lib/ui/icon/glyphs/shortTextLogos/nornikel.svg'
  ).then(({ ReactComponent }) => ({
    default: ReactComponent,
  })),
);

const elmagrouplLogo: BrandedLogo = lazy(() =>
  import(/* webpackChunkName: "elmagroupLogo" */ 'lib/ui/icon/glyphs/textLogos/elmagroup.svg').then(
    ({ ReactComponent }) => ({ default: ReactComponent }),
  ),
);

const elmagroupShortLogo = lazy(() =>
  import(
    /* webpackChunkName: "elmagroupShortLogo" */ 'lib/ui/icon/glyphs/shortTextLogos/elmagroup.svg'
  ).then(({ ReactComponent }) => ({
    default: ReactComponent,
  })),
);

const brandsData: Record<BrandName, BrandedData> = {
  officeservice: {
    clientApp: 'office.service',
    applicationName: 'ОФИС\u00A0СЕРВИС',
    applicationTitle: 'ОФИС\u00A0СЕРВИС',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1492901162?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=office.service',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103398739',
    logo: officeServiceLogo,
    appScheme: '',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  tagpoint: {
    clientApp: 'tag.point',
    applicationName: 'TAGPOINT.',
    applicationTitle: 'TagPoint',
    APP_STORE: 'https://apps.apple.com/app/id1581587003',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=tag.point',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C107653695',
    logo: tagpointLogo,
    shortLogo: tagpointShortLogo,
    appScheme: 'tagpoint://open.app',
    defaultLang: 'en',
    poweredBy: 'tagpoint',
    poweredByIcon: 'tp-black',
    loginOptions: {
      hasCheckbox: true,
      hasPassword: true,
    },
  },
  teorema: {
    clientApp: 'smartbc',
    applicationName: 'ТЕОРЕМА',
    applicationTitle: 'ТЕОРЕМА',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1021199196?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=ru.sbcs',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103397531',
    logo: teoremaLogo,
    appScheme: 'teorema://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  waveservice: {
    clientApp: 'smart.space',
    applicationName: 'WAVESERVICE.',
    applicationTitle: 'Wave\u00A0Service',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1139141510?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=space.smart',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    logo: waveServiceLogo,
    shortLogo: waveServiceShortLogo,
    landing: 'waveservice.ru',
    appScheme: 'waveservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  waveserviceEN: {
    clientApp: 'smart.space',
    applicationName: 'WAVESERVICE.',
    applicationTitle: 'Wave\u00A0Service',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1139141510?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=space.smart',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    logo: waveServiceLogo,
    appScheme: '',
    defaultLang: 'en',
    poweredBy: 'tagpoint',
    poweredByIcon: 'tp-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  landia: {
    clientApp: 'g.landia',
    applicationName: 'G-LANDIA Eco',
    applicationTitle: 'G-LANDIA Eco',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1615389766?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=g.landia.eco',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    logo: glandiaLogo,
    appScheme: '',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  zabotaapp: {
    clientApp: 'zabota.app',
    applicationName: 'С заботой о Вас!',
    applicationTitle: 'С заботой о Вас!',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6449288858?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=zabota.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C108466363',
    logo: zabotaLogo,
    shortLogo: zabotaShortLogo,
    appScheme: 'zabotaapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  suop: {
    clientApp: 'zabota.app',
    applicationName: 'С заботой о Вас!',
    applicationTitle: 'С заботой о Вас!',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6449288858?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=zabota.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C108466363',
    logo: zabotaLogo,
    shortLogo: zabotaShortLogo,
    appScheme: 'zabotaapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  gsp: {
    clientApp: 'gsp.service',
    applicationName: 'Мой\u00A0офис',
    applicationTitle: 'Мой\u00A0офис',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6466608312?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=gsp.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C109216723',
    logo: gsoLogo,
    appScheme: 'gspservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  'code-service': {
    clientApp: 'code.service',
    applicationName: 'CODE\u00A0SERVICE',
    applicationTitle: 'CODE\u00A0SERVICE',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6480408957?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=code.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C110697471',
    logo: codeserviceLogo,
    appScheme: 'codeservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  nornikel: {
    clientApp: 'nrnkl.app',
    applicationName: 'Забота-24',
    applicationTitle: 'Забота-24',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6504757959?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=nrnkl.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C111407111',
    logo: nornikelLogo,
    shortLogo: nornikelShortLogo,
    appScheme: 'nrnklapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: false,
    },
  },
  elma: {
    clientApp: 'elma.service',
    applicationName: 'ЭЛМА-Сервис',
    applicationTitle: 'ЭЛМА-Сервис',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6587573250?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=elma.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C111690645',
    logo: elmagrouplLogo,
    shortLogo: elmagroupShortLogo,
    appScheme: 'elmaservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
};

export default brandsData[brandName];
