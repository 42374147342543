import packageJson from '../../package.json';
import { BrandName } from '../types/brand';

export const fakeNull = '∫null∫';
export const isProduction = IS_PRODUCTION;
export const appVersion = packageJson.version;
export const appFullVersion = FULL_VERSION;
export const env = ENV;
export const brandName: BrandName = (() => {
  // You can change next line, but don't push this changes!!!
  let currentBrand: BrandName = 'waveservice';
  const brands: BrandName[] = [
    'officeservice',
    'tagpoint',
    'teorema',
    'waveservice',
    'landia',
    'zabotaapp',
    'suop',
    'gsp',
    'code-service',
    'nornikel',
    'elma',
  ];
  const shortBrands = [
    'sswp',
    'tpgo',
    'sbcs',
    'wsgo',
    'gl.wsgo',
    'znlmk',
    'suop',
    'gsp',
    'cdsrv',
    'zbt24',
    'e-grp',
  ];
  brands.some((brand, i) => {
    if (window.location.host === 'app.wave-service.com') {
      currentBrand = 'waveserviceEN';
      return true;
    }
    if (window.location.host === 'zabota-nlmk.ru' || window.location.host === 'nlmk.wavea.cc') {
      currentBrand = 'zabotaapp';
      return true;
    }
    if (window.location.host === 'gsp-mf-service.ru') {
      currentBrand = 'gsp';
      return true;
    }
    if (window.location.host === 'zabota-24.ru') {
      currentBrand = 'nornikel';
      return true;
    }
    if (window.location.host === 'elmagroup.ru') {
      currentBrand = 'elma';
      return true;
    }
    if (
      window.location.host.indexOf(brand) !== -1 ||
      window.location.host.indexOf(shortBrands[i]) !== -1
    ) {
      currentBrand = brand;
    }
    return false;
  });
  return currentBrand;
})();
